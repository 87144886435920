<template>
  <div>
    <el-card class="intanle">
      <span class="h1">{{ title }}</span>
      <span @click="goBack()">
        <el-button class="el-button1" type="info" style="float: right">戻る</el-button>
      </span>
    </el-card>
    <el-card>
      <!-- 情報入力 -->
      <el-form ref="form" label-position="right" label-width="20%" :rules="rules" :model="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品:" prop="productName">
              <el-input placeholder="商品を選択してください" v-model="form.productName" :disabled="disabled" style="width: 45%" @focus="istanchuangsSP = true"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="重み:" prop="weight">
              <el-input v-model="form.weight" onkeyup="value=value.replace(/[^\d]/g,'')" :disabled="disabled" placeholder="重みを入力してください" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="左側テキスト:" prop="fromLeftText">
              <!-- onkeyup="value=value.replace(/[^\d]/g,'')" -->
              <el-input type="textarea" :rows="10" v-model="form.fromLeftText"  :disabled="disabled" placeholder="左側テキストを入力してください" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="右側テキスト:" prop="fromRightText">
              <el-input v-model="form.fromRightText" :disabled="disabled" placeholder="右側テキストを入力してください" style="width: 45%"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="画像:" label-width="10%" class="is-required">
              <span class="el-icon-warning-outline" style="color: red" v-if="form.isMobile != '0'"><span style="margin-left: 10px">画像サイズの比率は1560px*800pxです</span> </span>
              <span class="el-icon-warning-outline" style="color: red" v-else><span style="margin-left: 10px">画像サイズの比率は1903px*545pxです</span> </span>
              <!-- 画像アップロード -->
              <!-- <img :src="firstPicId"> -->
              <!-- <el-upload
                v-if="form.isMobile != '0'"
                :limit="1"
                :show-file-list="true"
                :file-list="picture1"
                :action="UploadPics"
                list-type="picture-card"
                :on-success="success1"
                accept="image/*"
                :headers="MyHeader"
                :on-preview="handlePictureCardPreview1"
                :on-error="error1"
                :on-exceed="handleExceed"
                :before-remove="handleBeforeRemove1"
                :disabled="disabled"
                :class="{ hide_box: upload_btn }"
                style="width: 75.5%"
              >
                <i class="el-icon-plus"></i>
              </el-upload> -->
              <el-upload
                v-if="form.isMobile != '0'"
                :limit="1"
                :show-file-list="true"
                :file-list="picture1"
                action="#"
                :http-request="uploadImg"
                accept=".jpg,.png,.jpeg,.tif,.bmp,.gif"
                list-type="picture-card"
                :disabled="disabled"
                :class="{ hide_box: upload_btn }"
                style="width: 75.5%"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-upload
                v-else
                :limit="1"
                :show-file-list="true"
                :file-list="picture1"
                :action="UploadPics + '/1903/545'"
                list-type="picture-card"
                :on-success="success1"
                accept="image/*"
                :headers="MyHeader"
                :on-preview="handlePictureCardPreview1"
                :on-error="error1"
                :on-exceed="handleExceed"
                :before-remove="handleBeforeRemove1"
                :disabled="disabled"
                :class="{ hide_box: upload_btn }"
                style="width: 75.5%"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" style="text-align:center ;">
                <img height="400px" :src="picture1s" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="logtime" v-if="this.type != 'insert'">
          <el-col :span="12">
            <el-form-item label="作成日時:" label-width="20%">
              {{ createTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新日時:" label-width="20%">
              {{ updateTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作成者:" label-width="20%">
              {{ createPersonName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新者:" label-width="20%">
              {{ updatePersonName }}
            </el-form-item>
          </el-col>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="" v-if="this.type != 'details'">
              <el-button type="primary" @click="onSubmit('form')">{{ content }}</el-button>
              <el-button @click="goBack">キャンセル</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-dialog title="商品を選択してください" :visible.sync="istanchuangsSP" width="80%" center>
        <GetProductsListVue :istanchuang="istanchuangsSP" :isConfirmed="isConfirmed" ref="eidtList" @submitFormSP="submitFormSP"> </GetProductsListVue>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import GetProductsListVue from '../Products/GetProductsList.vue'
import axios from 'axios'
import slideService from '@/api/slide/slideService'

export default {
  inject: ['reload'],
  name: 'GetStaffDetails',
  components: {
    GetProductsListVue
  },
  data() {
    return {
      slideService: new slideService(), // インターフェース定義
      MyHeader: {
        accessToken: '',
        fp: ''
      },
      upload_btn: false, // 画像アップロードボタンの表示
      dialogVisible: false, // 画像アップロードダイアログの表示
      disabled: false, // 編集可能かどうか
      picture1: [], // スライド画像
      picture1s: '', // アップロードされた画像
      firstPicId: '',
      type: this.$route.query.type, // 遷移タイプ
      slideId: this.$route.query.slideId, // スライド画像ID
      istanchuangsSP: false, // 商品選択ダイアログの表示
      isConfirmed: '1', // 親子通信の値
      productName: '', // 親子通信で渡される商品名
      loading: false, // ローディング状態
      form: {
        productId: '', // 商品ID
        productName: '',
        pictureId: '', // アップロード画像ID
        weight: '', // 重み
        fromLeftText: '', // 左側テキスト
        fromRightText: '' // 右側テキスト
      },
      title: '', // 詳細タイトル
      content: '', // ボタンテキスト

      disabled: false, // 編集可能かどうか
      createTime: '', // 作成日時
      updateTime: '', // 更新日時
      createPersonName: '', // 作成者
      updatePersonName: '', // 更新者
      headerName: '', // パンくずリストのタイトル
      // 必須項目のバリデーション
      rules: {
        productId: [
          {
            required: true,
            message: '商品を選択してください',
            trigger: 'change'
          }
        ],
        pictureId: [
          {
            required: true,
            message: '画像IDを入力してください',
            trigger: 'blur'
          }
        ],
        weight: [
          {
            required: true,
            message: '重要度を入力してください',
            trigger: 'blur'
          }
        ]
      }
    }
  },

  created() {
    this.UploadPics = this.$url.UploadPicture
    this.UploadAvatars = this.$url.UploadAvatars
    this.MyHeader.accessToken = localStorage.getItem('authToken')
    this.MyHeader.fp = localStorage.getItem('fp')
    this.MyHeader.currentRole = localStorage.getItem('currentRole')

  },

  mounted() {
    if (this.type == 'details') {
      this.title = 'スライド画像詳細'
      this.headerName = 'スライド画像詳細'
      this.disabled = true
      this.upload_btn = true
      this.GetSlideDetail()
    } else if (this.type == 'update') {
      this.title = 'スライド画像編集'
      this.headerName = 'スライド画像編集'
      this.content = '保存'
      this.GetSlideDetail()
    } else if (this.type == 'insert') {
      this.title = 'スライド画像新規追加'
      this.headerName = 'スライド画像新規追加'
      this.content = '新規追加'
    }
  },

  methods: {
    // スライド画像の詳細を取得
    async GetSlideDetail() {
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        slideId: this.slideId // スライド画像ID
      }
      this.slideService.detail(date).then(res => {
        if (res.data.success == 'true') {
          this.form = res.data.result
          this.$set(this.form, 'fromLeftText', res.data.result.leftText)
          this.$set(this.form, 'fromRightText', res.data.result.rightText)
          this.$set(this.form, 'productName', res.data.result.productName1)
          this.createTime = res.data.result.createTime
          this.updateTime = res.data.result.updateTime
          this.createPersonName = res.data.result.createPerson
          this.updatePersonName = res.data.result.updatePerson
          this.picture1 = []
          if (this.form.fileId != '') {
            // var picIds = this.form.pictureId.split(',')
            // for (var i = 0; i < picIds.length; i++) {
            // }
            let uuu = {
              url: this.$url.urlcqs + this.form.fileId
            }
            this.picture1.push(uuu)
          }
          if (this.picture1.length != 0) {
            this.upload_btn = true
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    // スライド画像の新規追加
    onSubmit(formName) {
      if (this.type == 'insert') {
        if (this.form.pictureId == '') {
          this.$message.error('画像をアップロードしてください！');
          return false;
        }

        this.$refs[formName].validate(async valid => {
          if (valid) {
            const date = {
              accessToken: localStorage.getItem('authToken'),
              currentRole: localStorage.getItem('currentRole'),
              productId: this.form.productId,
              pictureId: this.form.pictureId,
              weight: this.form.weight,
              leftText: this.form.fromLeftText,
              rightText: this.form.fromRightText
            }
            // if (this.form.pictureId == '') {
            //   this.$message.error('画像をアップロードしてください！');
            //   return false;
            // }
            this.slideService.add(date).then(res => {
              if (res.data.success == 'true') {
                this.$message.success('保存に成功しました')
                this.goBack()
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
        })
      } else if (this.type == 'update') {
        this.UpdateSlide(formName)
      }
    },
    // スライド画像の更新
    async UpdateSlide(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const date = {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            slideId: this.slideId,
            productId: this.form.productId,
            pictureId: this.form.pictureId,
            weight: this.form.weight,
            leftText: this.form.fromLeftText,
            rightText: this.form.fromRightText
          }
          // if (this.form.pictureId == '') {
          //   this.$message.error('画像をアップロードしてください！');
          //   return false;
          // }
          this.slideService.update(date).then(res => {
            if (res.data.success == 'true') {
              this.$message.success('更新に成功しました')
              this.goBack()
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          return false
        }
      })
    },
    async formDataToBase64(formData) {
      let base64Data = {};
      
      for (let [key, value] of formData.entries()) {
          if (value instanceof File) {
              const fileReader = new FileReader();
              const promise = new Promise((resolve, reject) => {
                  fileReader.onload = function(e) {
                      resolve(e.target.result.split(',')[1]); // "data:image/*;"部分を削除し、Base64データのみを保持
                  };
                  fileReader.onerror = reject;
              });
              
              fileReader.readAsDataURL(value);
              base64Data[key] = await promise;
          }
      }
      
      
      return base64Data;
    },
    uploadImg(item) {
      this.loading = true
  // ファイルサイズが10MBを超えているかチェック
  // ファイルサイズが10MBを超えていないか確認する
      if (item.file.size > 10 * 1024 * 1024) {
        this.$message.error('アップロードするファイルのサイズは10MB以下にしてください！')
        this.picture1 = []
        return false
      }

      let originalFileExt = item.file.name.split('.')[1]

      // originalFileExtが[jpg,png,jpeg,tif,bmp,gif]に含まれているかチェック
      if (['jpg', 'png', 'jpeg', 'tif', 'bmp', 'gif'].indexOf(originalFileExt) == -1) {
        this.$message.error('アップロードするファイルの形式が正しくありません！')
        this.picture1 = []
        return false
      }
      

      let FormDatas = new FormData()
      FormDatas.append('file',item.file);
      let base64Img = '';

      this.formDataToBase64(FormDatas).then(base64Data => {
        base64Img = base64Data;

        let accessToken = localStorage.getItem('authToken');
        let currentRole = localStorage.getItem('currentRole');
        
        let params = {
          accessToken: accessToken,
          currentRole,
          file: base64Img.file,
          originalFileName: item.file.name,
          originalFileExt
        }
        // FormDatas.append('user_token', this.user_token);
        // console.log(this.UploadPics)
        axios({
          url: 'm_uploadFile',
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          timeout: 30000,
          data: params
        }).then( res => {
          this.form.pictureId = res.data.fileId;
        })
        // this.$http({
        //   method: 'post',
        //   url: this.UploadPics,
        //   headers: { 'Content-Type': 'multipart/form-data' },
        //   timeout: 30000,
        //   data: FormDatas
        //   }).then(res=>{

        //   })
      }).catch(error => {
        console.error('Error converting to Base64:', error);
      });

      
    },
    // 商品ダイアログを開く
    openmeSP() {
      this.istanchuangsSP = true
    },
    // 前のページに戻る
    goBack() {
      localStorage.setItem('updateState', true)
      this.$router.go(-1)
    },
    // ヘッダー画像のアップロード成功
    success1(response) {
      if (response.msgCode == 1) {
        this.videoForm.showVideoPath = ''
        this.firstPicId = response.id
        this.form.pictureId = response.id
        this.upload_btn = true
        this.$message.success('画像のアップロードに成功しました')
      } else if (response.msgCode !== '1') {
        this.$message({
          type: 'error',
          message: response.message
        })
      }
    },
    handlePictureCardPreview1(file) {
      this.picture1s = file.url
      this.dialogVisible = true
    },

    // ヘッダー画像のアップロード失敗
    error1: function(err, file, fileList) {
      this.$message.error('アップロードに失敗しました')
      if (fileList.length >= 1) {
        this.isMax = true
      } else {
        this.isMax = false
      }
    },
    handleExceed() {
      this.$message.warning('アップロードできる画像は1枚までです！')
    },
    handleBeforeRemove1(file, fileList) {
    // console.log('删了')
      var mm = ''
      if (this.type == 'update' && file.response == undefined) {
        mm = file.url.slice(42)
        return this.$confirm('このデータを永久に削除します。続行しますか？', '確認', {
          confirmButtonText: '確認',
          cancelButtonText: 'キャンセル',
          type: 'warning'
        }).then(() => {
          this.firstPicId = ''
          this.form.pictureId = ''
          this.picture1 = []
          this.upload_btn = false
        })
      } else {
        this.upload_btn = false
        mm = file.response.id
        let ids = new Set(this.firstPicId)
        ids.delete(mm)
        this.firstPicId = Array.from(ids)
      }
    },
    // ダイアログの確認ボタン
    submitFormSP() {
      // 子コンポーネントの入力内容を取得
      let item = this.$refs['eidtList'].passFather()
      if (item.length == 0) {
        this.form.productName = '商品を選択してください'
      } else {
      }

      
      // this.form.productId = item.productId
      // this.form.productName = item.productName1

      this.$set(this.form, 'productId', item.productId)
      this.$set(this.form, 'productName', item.productName1)

      this.istanchuangsSP = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-button1 {
  margin-top: 2px;
}

.el-card {
  margin-top: 15px;
}

textarea {
  height: 100px;
}

.hide_box /deep/ .el-upload--picture-card {
  display: none;
}

.avatar-uploader-icon {
  border: 1px dashed #d9d9d9 !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px !important;
  position: relative !important;
  overflow: hidden !important;
}

.avatar-uploader .el-upload:hover {
  border: 1px dashed #d9d9d9 !important;
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 300px;
  height: 178px;
  display: block;
}
</style>
